import React, { useEffect, useState } from 'react';
import { Select, Modal } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useLanguage } from '../../API/LanguageContext'; 
import axios from 'axios';
const { Option } = Select;

const LanguageSwitcher = () => {
    const { language, changeLanguage } = useLanguage();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleChange = (value) => {
        changeLanguage(value);
        setIsModalVisible(false); 
        localStorage.setItem('custom', value);
        
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const customLanguage = localStorage.getItem('custom');
        if(customLanguage){
            changeLanguage(customLanguage)
        }else{
            const detectLanguage = async () => {
                try {
                  const response = await axios.get('https://ipapi.co/json/');
                  const countryCode = response.data.country_code;
                  const languageMapping = {
                    ES: 'es',
                    FR: 'fr',
                    NL: 'nl',
                  };
                  const detectedLanguage = languageMapping[countryCode] || 'en';
                  changeLanguage(detectedLanguage);
                  localStorage.setItem('auto', detectedLanguage);
                } catch (error) {
                  console.error('Error detecting location:', error);
                }
              };
          
              detectLanguage();
        }
      
      }, [changeLanguage]);

    return (
        <>
            <Select
                value={language}
                onChange={handleChange}
                style={{ cursor: 'pointer' }}
                className="custom-dropdown"
            >
                <Option value="en">English</Option>
                <Option value="es">Español</Option>
                <Option value="fr">Français</Option>
                <Option value="nl">Dutch</Option>
            </Select>

            <GlobalOutlined
                className="language-icon"
                onClick={showModal}
            />

            <Modal
                title="Select Language"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Select
                    value={language}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                >
                    <Option value="en">English</Option>
                    <Option value="es">Español</Option>
                    <Option value="fr">Français</Option>
                    <Option value="nl">Dutch</Option>
                </Select>
            </Modal>
        </>
    );
};

export default LanguageSwitcher;
